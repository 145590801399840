import HeaderLogoBox from './HeaderLogoBox';
import HeaderCenter from './HeaderCenter';


const Header = () => {
    return (
        <div className="header">
            <HeaderLogoBox/>
            <HeaderCenter/>
        </div>
    )
}
export default Header;