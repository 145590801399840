import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Routing from './components/Routing/Routing';
import Navigation from './components/Navigation/Naviation';
import CookiesAlert from './components/CookiesAlert/CookiesAlert'
import {cookiesStore} from "./components/Cookies/cookies-store";
import {useState, useEffect} from "react";
import LanguageChoice from "./components/LanguageChoice/LanguageChoice";
import {choiceStore} from "./components/LanguageChoice/choice-store";

function App() {

    // //===========cookiesStore ================
    const setCookie = cookiesStore((state) => state.setCookie)
    const getCookie = cookiesStore((state) => state.getCookie)
    // //===========cookiesStore ================

    //===============For Navigation==================
    const [navigationVisible, setNavigationVisible] = useState(false);
    const toggleNavigation = () => {
        if (navigationVisible) {
            setNavigationVisible(false);
        } else {
            setNavigationVisible(true);
        }
    }
    //===============For Navigation==================


    //===============For CookieBanner==================
    const [cookiesAlertVisible, setCookiesAlertVisible] = useState(true);
    const hideCookiesAlert = () => {
        if (cookiesAlertVisible) {
            setCookie('n-TGR', 'visited', 1);
            setCookiesAlertVisible(false);
        } else {
            setCookiesAlertVisible(true);
        }
    }
    useEffect(() => {
            if (getCookie('n-TGR') === 'visited') {
                setCookiesAlertVisible(false);
            }
        }
        , [cookiesAlertVisible]
    );
    //===============For CookieBanner==================


    return (

        <div className="App">
            <LanguageChoice/>
            <label onClick={toggleNavigation} className="navigation-button">{" "}
                {!navigationVisible && <span className="navigation-icon-show">{" "}</span>}
                {navigationVisible && <span className="navigation-icon-hide">{" "}</span>}
            </label>

            {navigationVisible && <Navigation onHide={toggleNavigation}/>}
            {cookiesAlertVisible && <CookiesAlert onHide={hideCookiesAlert}/>}

            <Header/>
            <Routing/>
            <Footer/>
        </div>
    );
}

export default App;