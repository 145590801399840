import CustomerMenuModal from './CustomerMenuPopup/CustomerMenuModal/CustomerMenuModal';
import {vacanciesStore} from "../Vacancies/vacancies-store";
import {customersStore} from "../Customers/customers-store";
import {useEffect} from "react";
import {choiceStore} from "../LanguageChoice/choice-store";


const CustomerMenu = (props) => {

    //===========vacanciesStore ================
    // const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    const fetchVacanciesByCustomer = vacanciesStore((state) => state.fetchVacanciesByCustomer);
    const fetchCustomersWithVacancies = vacanciesStore((state) => state.fetchCustomersWithVacancies);
    const customersWithVacancies = vacanciesStore((state) => state.subSetCustomers)
    //=========== vacanciesStore ================


//===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    let allText = "All";
    if(nederlands) {
        allText = "Alle";
    }

        const getVacanciesByCustomer = (event) => {
        console.log('input: ' + event.target.id);
        fetchVacanciesByCustomer(event.target.id).then();
        props.onHide();
    }

    useEffect(() => {
        fetchCustomersWithVacancies().then();
    }, [])


    const customerList = customersWithVacancies.map((customer, i) =>
        <div className="customer-menu-item" id={i} key={i}>
            <span className="customer-menu-button customer-menu-button-txt"
                  id={customer}
                  onClick={getVacanciesByCustomer}>
                {customer}
            </span>
        </div>
    );

    return (
        <CustomerMenuModal onClose={props.onHide}>
            <div className="customer-menu">
                <div className="customer-menu-item" id="none">
                    <span className="customer-menu-button customer-menu-button-txt" id="none"
                          onClick={getVacanciesByCustomer}>{allText}
                    </span>
                </div>
                {customerList}
            </div>
        </CustomerMenuModal>
    );
}
export default CustomerMenu;