import {Link} from "react-router-dom";
import {choiceStore} from "../LanguageChoice/choice-store";
import {useEffect} from "react";
const Introduction = () => {

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================
    let welcomeText = "";
    let focusText = "";

    if(nederlands){
        welcomeText = "Welkom bij n-TGR laten we gaan samenwerken";
        focusText = "Focus door integriteit";
    }
    if(!nederlands){
        welcomeText = "Welcome to n-TGR let's start to collaborate";
        focusText = "Integrity makes it easy to focus";
    }


    return (
        <div className="introduction">
            <Link to="/join-us-page" className="introduction-part introduction-header">{welcomeText}</Link>
            <Link to="/about-us-page" className="introduction-part introduction-statement">{focusText}</Link>
        </div>)
}
export default Introduction;