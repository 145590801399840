import {Route, Routes} from 'react-router-dom';
import VacancyPage from '../VacancyPage/VacancyPage';
import Main from '../Main/Main';
import ContactPage from '../ContactPage/ContactPage';
import VacanciesPage from '../VacanciesPage/VacanciesPage';
import JoinUs from "../JoinUs/JoinUs";
import AboutUs from "../AboutUs/AboutUs";
import ApplyForJob from "../ApplyForJob/ApplyForJob";
import NTGRVacanciesPage from "../NTGRVacanciesPage/NTGRVacanciesPage";
import NTGRVacancyPage from "../NTGRVacancyPage/NTGRVacancyPage";


const Routing = () => {

    return (
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/vacancy-page" element={<VacancyPage/>}/>
            <Route path="/contact-page" element={<ContactPage/>}/>
            <Route path="/vacancies-page" element={<VacanciesPage/>}/>
            <Route path="/join-us-page" element={<JoinUs/>}/>
            <Route path="/about-us-page" element={<AboutUs/>}/>
            <Route path="/apply-for-job" element={<ApplyForJob/>}/>
            <Route path="/ntgr-vacancies-page" element={<NTGRVacanciesPage/>}/>
            <Route path="/ntgr-vacancy-page" element={<NTGRVacancyPage/>}/>
        </Routes>
    );
}

export default Routing;