import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {vacancyPageStore} from "./vacancy-page-store";
import VacancyResponse from '../VacancyResponse/VacancyResponse';
import {choiceStore} from "../LanguageChoice/choice-store";


const VacancyPage = () => {
    //Can not be a page on top, it has to be a page by itself because of the content.
    const vacancyPageVisible = vacancyPageStore((state) => state.vacancyPageVisible);
    const setVacancyPageVisible = vacancyPageStore((state) => state.setVacancyPageVisible);


    let vacancies = [];
    let currentVacancy = null;
    const [vacancyResponseVisible, setVacancyResponseVisible] = useState(false);

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================
    let cityText = "City";
    let educationText = "Education";
    let endDateText = "End Date";
    let startDateText = "Start Date";
    let hoursText = "Hours";
    let optionToExtendText = "Option to extend";
    let optionToExtend = "Yes";
    let descriptionText = "Description";
    let requirementsText = "Requirements";
    let applyText = "Apply";

    if(nederlands){
        cityText = "Stad";
        educationText = "Opleiding";
        endDateText = "Eind Datum";
        startDateText = "Begin Datum";
        hoursText = "Aantal uren";
        optionToExtendText = "Verlening mogelijk";
        optionToExtend = "Ja";
        descriptionText = "Beschrijving";
        requirementsText = "Eisen"
        applyText = "Solliciteer"
    }

    const navigate = useNavigate();

    const showVacancyResponse = () => {
        setVacancyResponseVisible(true);
    }
    const hideVacancyResponse = () => {
        setVacancyResponseVisible(false);
    }



    useEffect(() => {
        let Vacancies = sessionStorage.getItem('Vacancies');
        if (!Vacancies) {
            navigate('/');
        }

    }, [navigate]);

    vacancies = JSON.parse(sessionStorage.getItem('Vacancies'));//         1. get the cache
    let vacancyId = sessionStorage.getItem('currentVacancyId');//              2. get the vacancyId
    if (vacancies) {//only if there are vacancies you should do this. Remember useEffect will fire but the rest will also be done  so that needs protecting.
        currentVacancy = vacancies.find(val => val.vacancyId == vacancyId);//3. get the currentVacancy
    }
    //
    if (!vacancyPageVisible) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        setVacancyPageVisible(true);
    }

    return (
        <div id="vacancy-page" className="vacancy-page">
            <div className="vacancy-page-container">
                {currentVacancy &&
                    <div className="vacancy-page-container-content">
                   <span className="vacancy-page-heading">
                      {currentVacancy.name}
                   </span>
                        <div className="vacancy-page-label vacancy-page-label-city">{cityText}</div>
                        <div className="vacancy-page-label vacancy-page-label-education">{educationText}</div>
                        <div className="vacancy-page-label vacancy-page-label-end-date">{endDateText}</div>
                        <div className="vacancy-page-label vacancy-page-label-start-date">{startDateText}</div>
                        <div className="vacancy-page-label vacancy-page-label-hours-per-week">{hoursText}</div>
                        <div className="vacancy-page-label vacancy-page-label-option-to-extend">{optionToExtendText}</div>
                        <div className="vacancy-page-text vacancy-page-text-city">{currentVacancy.city}</div>
                        <div className="vacancy-page-text vacancy-page-text-education">{currentVacancy.education}</div>
                        <div className="vacancy-page-text vacancy-page-text-end-date">{currentVacancy.end_date}</div>
                        <div
                            className="vacancy-page-text vacancy-page-text-start-date">{currentVacancy.start_date}</div>
                        <div className="vacancy-page-text vacancy-page-text-hours-per-week">{currentVacancy.hours}</div>
                        <div className="vacancy-page-text vacancy-page-text-option-to-extend">{optionToExtend}</div>
                        <div className="vacancy-page-label vacancy-page-label-description">{descriptionText}</div>
                        <div className="vacancy-page-text vacancy-page-text-description"
                             dangerouslySetInnerHTML={{__html: currentVacancy.description}}/>
                        <div className="vacancy-page-divider vacancy-page-divider-2">{""}</div>
                        <div className="vacancy-page-label vacancy-page-label-requirements">{requirementsText}</div>
                        <div className="vacancy-page-text vacancy-page-text-requirements"
                             dangerouslySetInnerHTML={{__html: currentVacancy.requirements}}/>
                        <div className="vacancy-page-respond-container">
                            <div className="btn vacancy-page-respond" onClick={showVacancyResponse}>{applyText}</div>
                        </div>
                    </div>}

            </div>
            {/*This part is the VacancyResponse that is shown when the response button is clicked.*/}
            {vacancyResponseVisible && <VacancyResponse hideVacancyResponse={hideVacancyResponse} currentVacancy={currentVacancy}/>}
        </div>
    )
        ;
}
export default VacancyPage;