import {Link} from 'react-router-dom';
import NTGRSlider from "../NTGRSlider/NTGRSlider";
import {vacanciesStore} from "../Vacancies/vacancies-store";
import {customersStore} from "./customers-store";
import logo_police from '../../assets/images/svg/politie_logo.svg';
import logo_ns from '../../assets/images/svg/nslogo.svg';
import logo_utrecht from '../../assets/images/svg/utrecht.svg';
import logo_amsterdam from '../../assets/images/svg/amsterdam.svg';
import logo_atos from '../../assets/images/svg/atos.svg';
import logo_denbosch from '../../assets/images/svg/denbosch.svg';
import logo_gemvision from '../../assets/images/svg/gemvision.svg';
import logo_pggm from '../../assets/images/svg/pggm.svg';
import logo_telenet from '../../assets/images/svg/telenet.svg';
import logo_kadaster from '../../assets/images/svg/kadaster.svg';
import logo_rotterdam from '../../assets/images/svg/rotterdam.svg';


const Customers = (props) => {

    //===========vacanciesStore ================
    // const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    const fetchVacanciesByCustomer = vacanciesStore((state) => state.fetchVacanciesByCustomer);
    //=========== vacanciesStore ================

    const logos = [
        {url:logo_amsterdam,title:"Amsterdam"},
        {url:logo_police,title:"Politie"},
        {url:logo_ns,title:"NS"},
        {url:logo_utrecht,title:"Utrecht"},
        {url:logo_atos,title:"Atos"},
        {url:logo_denbosch,title:"Den Bosch"},
        {url:logo_gemvision,title:"Gemvision"},
        {url:logo_pggm,title:"PGGM"},
        {url:logo_telenet,title:"Telenet"},
        {url:logo_kadaster,title:"Kadaster"},
        {url:logo_rotterdam,title:"Rotterdam"}
    ]

    const getVacanciesByCustomer = (event) => {
        console.log('input: ' + event.target.id);
        fetchVacanciesByCustomer(event.target.id).then();
        props.onHide();
    }


    return (
        <div className="customers">

            {/*<Link to="/vacancies-page/" className="customer" id="Politie">*/}
            {/*    <img src={logo_police} alt="Police" className="customers-button" id="Politie"*/}
            {/*         onClick={getVacanciesByCustomer}/>*/}
            {/*</Link>*/}
            {/*<Link to="/vacancies-page/" className="customer" id="NS">*/}
            {/*    <img src={logo_ns} alt="" className="customers-button" id="NS"*/}
            {/*         onClick={getVacanciesByCustomer}/>*/}
            {/*</Link>*/}
            {/*<Link to="/vacancies-page/" className="customer" id="Amsterdam">*/}
            {/*    <img src={logo_amsterdam} alt="" className="customers-button" id="Amsterdam"*/}
            {/*         onClick={getVacanciesByCustomer}/>*/}
            {/*</Link>*/}
            <NTGRSlider logos={logos}/>
        </div>
    );
}
export default Customers;