import {create} from "zustand";

const cookiesStore = create((set) => ({
    setCookie: (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie:(cname) =>{
        let name = cname + "=";//cname = cookie name is the cookie name and lives before the = sign.
        let decodedCookie = decodeURIComponent(document.cookie);//This is to handle cookies with special characters
        let ca = decodedCookie.split(';');//ca is the cookie array because the cookie is a string with ; as separator.
        for (let i = 0; i < ca.length; i++) {//read all cookies array elements
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substr(1);
            }
            if (c.indexOf(name) === 0) {//Once you find the name then you return a value.
                return c.substr(name.length, c.length);
            }
        }
        return "";//If you find nothing you return an empty string.
    },
    removeCookie:(cname,cvalue)=>{
        document.cookie = cname + "=" + cvalue + "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    }
}));

export {cookiesStore};