import SalaryExampleModal from './SalaryExamplePopup/SalaryExampleModal';
import {choiceStore} from "../LanguageChoice/choice-store";

const SalaryExample = (props) => {

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================

    const hideSalaryExample = () => {
        props.onHide();
    }

    return (
        <SalaryExampleModal onClose={props.onHide}>
            {!nederlands &&
            <div className="salary-example">
                <div className="salary-example-content salary-example-close" onClick={hideSalaryExample}>X</div>

                <div className="salary-example-content salary-example-text salary-example-hourly-rate-text">Hourly Rate</div>
                <div className="salary-example-content salary-example-number salary-example-hourly-rate-number">€ 90</div>
                <div className="salary-example-content salary-example-text salary-example-revenue-text">Your revenue 160 x 0.7 x €90</div>
                <div className="salary-example-content salary-example-number salary-example-revenue-number">€ 10.080</div>
                <div className="salary-example-content salary-example-text salary-example-base-salary-text">Base Salary</div>
                <div className="salary-example-content salary-example-number salary-example-base-salary-number">€ 3.700</div>
                <div className="salary-example-content salary-example-text salary-example-revenu-part-text">70% of revenue minus base salary</div>
                <div className="salary-example-content salary-example-number salary-example-revenu-part-number">€ 6.380</div>
                <div className="salary-example-content salary-example-text salary-example-gross-salary-text">Gross Salary</div>
                <div className="salary-example-content salary-example-number salary-example-gross-salary-number">€ 10.080</div>
            </div>
            }
            {nederlands &&
                <div className="salary-example">
                    <div className="salary-example-content salary-example-close" onClick={hideSalaryExample}>X</div>

                    <div className="salary-example-content salary-example-text salary-example-hourly-rate-text">Uur tarief</div>
                    <div className="salary-example-content salary-example-number salary-example-hourly-rate-number">€ 90</div>
                    <div className="salary-example-content salary-example-text salary-example-revenue-text">Jouw omzet 160 x 0.7 x €90</div>
                    <div className="salary-example-content salary-example-number salary-example-revenue-number">€ 10.080</div>
                    <div className="salary-example-content salary-example-text salary-example-base-salary-text">Basis Salaris</div>
                    <div className="salary-example-content salary-example-number salary-example-base-salary-number">€ 3.700</div>
                    <div className="salary-example-content salary-example-text salary-example-revenu-part-text">70% van de omzet minus basis salaris</div>
                    <div className="salary-example-content salary-example-number salary-example-revenu-part-number">€ 6.380</div>
                    <div className="salary-example-content salary-example-text salary-example-gross-salary-text">Bruto Salaris</div>
                    <div className="salary-example-content salary-example-number salary-example-gross-salary-number">€ 10.080</div>
                </div>
            }
        </SalaryExampleModal>
    );
}
export default SalaryExample;