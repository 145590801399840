import AboutUsContent from './AboutUsContent';
import {choiceStore} from "../LanguageChoice/choice-store";

const AboutUs = () => {
//===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================

    const aboutUsContent = [
        {
            icon: "fa-thin fa-anchor",
            english: {
                header: "FOUNDATION",
                text: " After a number of adventures with different business forms, Sander van Hijfte thought it was time to set up a company where INTEGRITY is the starting point for both the organisation and the employee. Without hidden agendas, it's easy to operate. We don’t have year-end or performance reviews. The only one who can appreciate your performance is the customer. So the reward of hard work is not due to nothing else but your SKILLS. That is reflected in the only measure we need, the markets appreciation."
            },
            dutch: {
                header: "FUNDAMENT",
                text: " Sander van Hijfte dacht na een aantal avonturen dat het tijd was een bedrijf op te richten waar INTEGRITEIT het uitgangspunt vormt voor zowel de organisatie als medewerker. Zonder verborgen agendas is opereren eenvoudig. We kennen geen einde jaars reviews. De enige die jouw werk kent is de klant. Dus de beloning voor hard werk is alleen te wijten aan jouw VAARDIGHEDEN. Dat reflecteert in de enige waardering die we gebruiken, de markt."
            }
        },
        {
            icon: "fa-thin fa-brackets-curly",
            english: {
                header: "FOR",
                text: "We are CRAFTS PEOPLE. We love IT and everything about it. As one of our own always states: “It is just IT with a certain saus”. So we understand .NET, Java, GIS, APPS, Web, SAP, Low Code, Security and more. We like to focus on delivering the best solution for our customer without worrying about our next promotion or office politics. Everybody is equal because the number of hours determine your income, not your gender, rase or creed. INCLUSIVENESS is a GIVEN not some vague policy."
            },
            dutch: {
                header: "VOOR",
                text: " Wij zijn VAK MENSEN. We houden van IT in al zijn facetten. Zoals een van ons altijd zegt: “Het is gewoon IT met een sausje”. Dus wij begrijpen .NET, Java, GIS, APPS, Web, SAP, Low Code, Security en nog veel meer. We houden ervan om de best mogelijke oplossing te leveren voor onze klant zonder zorgen over promoties of interne politiek. Idereen is gelijk omdat het aantal uren je inkomen bepaalt, niet je geslacht, ras of geloof. INCLUSIVITEIT is een GEGEVEN niet een vaag beleid."
            }
        },
        {
            icon: "fa-thin fa-seedling",
            english: {
                header: "BECOMING",
                text: "We can talk about our CRAFT in-depth and for hours, because we really want to UNDERSTAND what we create and WHY our software BEHAVES in a certain way. Your carrier is yours and we will help you develop into whatever you want to become. Our customers need people that can deliver, not in a year, but straight away. So we have tons of great training opportunities that will only cost you time. If you want to be certified, we will also make that happen. So join us to be the great IT consultant you know you are."
            },
            dutch: {
                header: "EEN",
                text: "We kunnen uren, en in detail discussiëren, over ons VAK omdat we willen BEGRIJPEN wat we creëren en WAAROM onze software zich op een bepaalde manier GEDRAAGT. Het is jouw carriere die wij helpen te ontwikkelen zodat je wordt wat je wilt zijn. Onze klanten hebben behoeft aan mensen die direct kunnen leveren. Daarom hebben we veel mogelijkheden om je te scholen en het enige dat je investeert is tijd. Ook certificering regelen we. Dus sluit je bij ons aan als de top IT consultant die je bent."
            }
        },
        {
            icon: "fa-thin fa-people-roof",
            english: {
                header: "TEAM",
                text: "A team is a group of individuals that RESPECT and COMPLEMENT each other. We are willing to debate any aspect in a professional way. That means nothing is given and all can be changed if need be. Initiative is greatly appreciated and your input is of great value. Every team member can depend on the team for backup, support and assistance. We ALWAYS pick up the phone for our members and we NEVER deny professional guidance. This way we add value for all in our company, and form a great TEAM."
            },
            dutch: {
                header: "TEAM",
                text: " Een team is een groep individuen die elkaar RESPECTEREN en COMPLEMENTEREN. We zijn bereid om elk aspect op een professionele manier te bespreken. Dat betekent dat niets vast staat en alles, indien nodig, kan veranderen . Initiatief wordt zeer gewaardeerd en jouw input is van grote waarde. Elk team lid kan op het team rekenen voor backup, ondersteuning en hulp. We nemen ALTIJD onze telefoon op en we weigeren elkaar NOOIT professionele ondersteuning. Zo voegen we waarde toe, en vormen we een geweldig TEAM."
            }
        }
    ]


    return (
        <div>
            {!nederlands &&
            <div className="about-us">
                <div className="about-us-founding">
                    <AboutUsContent
                        icon={aboutUsContent[0].icon}
                        header={aboutUsContent[0].english.header}
                        text={aboutUsContent[0].english.text}
                    />
                </div>
                <div className="about-us-for">
                    <AboutUsContent
                        icon={aboutUsContent[1].icon}
                        header={aboutUsContent[1].english.header}
                        text={aboutUsContent[1].english.text}
                    />
                </div>
                <div className="about-us-develop">
                    <AboutUsContent
                        icon={aboutUsContent[2].icon}
                        header={aboutUsContent[2].english.header}
                        text={aboutUsContent[2].english.text}
                    />
                </div>

                <div className="about-us-team">
                    <AboutUsContent
                        icon={aboutUsContent[3].icon}
                        header={aboutUsContent[3].english.header}
                        text={aboutUsContent[3].english.text}
                    />
                </div>
            </div>
            }
            {nederlands &&
                <div className="about-us">
                    <div className="about-us-founding">
                        <AboutUsContent
                            icon={aboutUsContent[0].icon}
                            header={aboutUsContent[0].dutch.header}
                            text={aboutUsContent[0].dutch.text}
                        />
                    </div>
                    <div className="about-us-for">
                        <AboutUsContent
                            icon={aboutUsContent[1].icon}
                            header={aboutUsContent[1].dutch.header}
                            text={aboutUsContent[1].dutch.text}
                        />
                    </div>
                    <div className="about-us-develop">
                        <AboutUsContent
                            icon={aboutUsContent[2].icon}
                            header={aboutUsContent[2].dutch.header}
                            text={aboutUsContent[2].dutch.text}
                        />
                    </div>

                    <div className="about-us-team">
                        <AboutUsContent
                            icon={aboutUsContent[3].icon}
                            header={aboutUsContent[3].dutch.header}
                            text={aboutUsContent[3].dutch.text}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
export default AboutUs;