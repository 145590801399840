import {useState, useEffect} from "react";
import {vacanciesStore} from "./vacancies-store";
import CustomerMenu from '../CustomerMenu/CustomerMenu';
import {choiceStore} from "../LanguageChoice/choice-store";
import Vacancy from "../Vacancy/Vacancy";


const Vacancies = (props) => {
    //===========vacanciesStore ================
    const loadingVacancies = vacanciesStore((state) => state.loadingVacancies);
    const vacancies = vacanciesStore((state) => state.vacancies);
    //=========== vacanciesStore ================

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================


    let customersText = "CUSTOMERS";
    let loadingText = "Loading vacancies ...";
    let noVacanciesText = "No vacancies at this moment please try a different customer";

    if (nederlands) {
        customersText = "KLANTEN";
        loadingText = "Vactures aan het laden ..."
        noVacanciesText = "Geen vacatures op dit moment, probeer een andere klant";
    }


    //We need to know which page is loading the vacancies
    const [vacanciesPage, setVacanciesPage] = useState(false);
    // const [main, setMain] = useState(false);

    // const selectVacancy = (event) => {
    //     sessionStorage.setItem('currentVacancyId', event.target.id);
    // }

    useEffect(() => {
        //We determine which page is loading the vacancies!!
        if (props.page === 'VacanciesPage') {
            setVacanciesPage(true);
        }

    }, [vacancies.length, props.page]);



    const vacancyList = vacancies.map(vacancy =>
        <Vacancy vacancy={vacancy}/>
    );


    const noVacancies = <p className="vacancies-no-vacancies">{noVacanciesText}</p>

    //Toggling the visibility of the CustomerMenu
    const [customerMenuVisible, setCustomerMenuVisible] = useState(false);
    const toggleCustomerMenu = () => {
        if (customerMenuVisible) {
            setCustomerMenuVisible(false);
        } else {
            setCustomerMenuVisible(true);
        }
    }


    return (
        <div className="vacancies">
            {customerMenuVisible &&
                <CustomerMenu onHide={toggleCustomerMenu} className="vacancies-cell-content"/>
            }
            <div className="vacancies-container">
                    <div className="vacancies-cell">
                        <div className="vacancies-cell-content vacancies-customer-button" onClick={toggleCustomerMenu}>
                            {customersText}
                        </div>
                    </div>
                {loadingVacancies && <p className="vacancies-no-vacancies">{loadingText}</p>}
                {/*<The number of vacancies loaded is 4 when on the main page>*/}
                {!loadingVacancies && vacancies.length > 0 && vacancyList}
                {!loadingVacancies && vacancies.length === 0 && noVacancies}
            </div>
        </div>

    );
}
export default Vacancies;