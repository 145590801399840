import {ntegerVacanciesStore} from '../Vacancies/nteger-vacancies-store';
import {useEffect} from "react";
import Vacancy from "../NTGRVacancy/NTGRVacancy";
import {choiceStore} from "../LanguageChoice/choice-store";
import {vacanciesStore} from "../Vacancies/vacancies-store";

const NTGRVacanciesPage = () => {
    //=========== ntegerVacanciesStore ================
    const loadingVacancies = ntegerVacanciesStore((state) => state.loadingVacancies);
    const NtegerVacancies = ntegerVacanciesStore((state) => state.NtegerVacancies);
    const fetchNtegerVacancies = ntegerVacanciesStore((state) => state.fetchNtegerVacancies);
    //=========== ntegerVacanciesStore ================


    //===========vacanciesStore ================
    const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    //=========== vacanciesStore ================

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================

    let headingText = "n-TGR Vacancies";
    let loadingText = "Loading vacancies ...";
    let noVacanciesText = "No vacancies at this moment";
    if (nederlands) {
        headingText = "n-TGR Vacatures";
        noVacanciesText = "Geen vacatures op dit moment";
        loadingText = "Vactures aan het laden ..."
    }

    useEffect(() => {
        let cache = sessionStorage.getItem('Vacancies');
        if (cache) {
            fetchNtegerVacancies().then();
        }else{
            fetchVacancies().then(fetchNtegerVacancies);
        }
    }, [fetchNtegerVacancies]);

    console.log(NtegerVacancies.length);

    const vacancyList = NtegerVacancies.map(vacancy =>
        <Vacancy vacancy={vacancy}/>
    );
    const noVacancies = <p className="vacancies-no-vacancies">{noVacanciesText}</p>

    return (
        <div className="ntgr-vacancies">
            <div className="ntgr-vacancies-header">
                {headingText}
            </div>
            <div className="ntgr-vacancies-content">
                <div className="vacancies">
                    <div className="vacancies-container">
                        {loadingVacancies && <p className="vacancies-no-vacancies">{loadingText}</p>}
                        {!loadingVacancies && NtegerVacancies.length > 0 && vacancyList}
                        {!loadingVacancies && NtegerVacancies.length === 0 && noVacancies}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NTGRVacanciesPage;