import {create} from 'zustand';

const ntegerVacanciesStore = create((set) => ({
            NtegerVacancies: [],
            loadingVacancies: false,
            fetchNtegerVacancies: async () => {
                set(() => ({loadingVacancies: true}));
                let subSet = [];
                let cacheVacancies = JSON.parse(sessionStorage.getItem('Vacancies'));
                try {
                    for (let item of cacheVacancies) {
                        if (item.customer === "Nteger") {
                            subSet.push(item);
                        }
                    }

                    if (subSet.length > 0) {
                        set((state) => ({data: (state.NtegerVacancies = subSet), loadingVacancies: false}));
                        sessionStorage.setItem('NtegerVacancies', JSON.stringify(subSet));
                    } else {
                        set((state) => ({loadingVacancies: false}));
                    }
                } catch (err) {
                    // set((state) => (state.NtegerVacanciesHasErrors = true));
                    set(() => ({NtegerVacanciesHasErrors: true, loadingVacancies: false}));
                }

            },
            fetchFirstThreeNtegerVacancies: async () => {
                set(() => ({loadingVacancies: true}));
                let subSet = [];
                let cacheVacancies = JSON.parse(sessionStorage.getItem('NtegerVacancies'));

                try {
                    if (cacheVacancies.length > 2) {
                        for (let i = 0; i < 3; i++) {
                            subSet.push(cacheVacancies[i]);
                        }
                    } else {
                        for (let item of cacheVacancies) {
                            subSet.push(item);
                        }
                    }
                    set((state) => ({data: (state.NtegerVacancies = subSet), loadingVacancies: false}));
                    sessionStorage.setItem('NtegerVacancies', JSON.stringify(subSet));
                } catch (err) {
                    set(() => ({NtegerVacanciesHasErrors: true, loadingVacancies: false}));
                }
            }
        }
    )
);

export {ntegerVacanciesStore}