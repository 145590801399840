import {Link} from 'react-router-dom';
const Footer = () => {
    return (
        <div className="footer" id="footer">
            <div className="footer-box">
                <div className="footer-label">
                    <a href="mailto:info@n-tgr.nl" className="fa-thin fa-envelope footer-label-icon">{" "}</a>
                    <a href="mailto:info@n-tgr.nl" className="footer-label">{" "}info@n-tgr.nl</a>
                </div>
            </div>
            <div className="footer-box">
                <div className="footer-label">
                    <a href="https://maps.google.fr/maps?q=Prins Mauritslaan 14, 5263 AX" className="fa-thin fa-map-marker-alt footer-label-icon">{" "}</a>
                    <a href="https://maps.google.fr/maps?q=Prins Mauritslaan 14, 5263 AX" target="_blank" className="footer-label">{" "}Prins Mauritslaan 14, 5263 AX Vught Nederland </a>


                </div>
            </div>
            <div className="footer-box">
                <div className="footer-label">
                    <a href="https://www.kvk.nl/bestellen/#/17200491000015183084" className="fa-thin fa-bank footer-label-icon">{" "}</a>
                    <a href="https://www.kvk.nl/bestellen/#/17200491000015183084" className="footer-label">{" "} 17200491</a>

                </div>
            </div>
            <div className="footer-box">
                <div className="footer-label">
                    <a href="tel:+31850470361" className="fa-thin fa-phone footer-label-icon">{" "}</a>
                    <a href="tel:+31850470361" className="footer-label">{" "}+31 85 04 70 361</a>
                </div>
            </div>
        </div>
    );
}
export default Footer;