import ContactModal from './ContactPopup/ContactModal';

const Contact=(props)=>{

    const hideContact = () => {
        props.onHide();
    }
    
    return(
        <ContactModal onClose={props.onHide}>
            <div className="contact">
                <div className="contact-content contact-close" onClick={hideContact}>X</div>

                <a href="https://maps.google.fr/maps?q=Prins Mauritslaan 14, 5263 AX" className="fa-thin fa-map-marker-alt contact-icon contact-address-icon">{" "}</a>
                <a href="https://maps.google.fr/maps?q=Prins Mauritslaan 14, 5263 AX" className="contact-content contact-text contact-address-text">Prins Mauritslaan 14, 5263 AX Vught</a>

                <a href="tel:+31850470361" className="fa-thin fa-phone contact-icon contact-telephone-icon">{" "}</a>
                <a href="tel:+31850470361" className="contact-content contact-text contact-telephone-text">+31 85 04 70 361</a>

                <a href="mailto:info@n-tgr.nl" className="fa-thin fa-envelope contact-icon contact-mail-icon">{" "}</a>
                <a href="mailto:info@n-tgr.nl" className="contact-content contact-text contact-email-text">info@n-tgr.nl</a>
                
            </div>
        </ContactModal>
    )
    
}
export default Contact;