import {Link} from "react-router-dom";
import {choiceStore} from "../LanguageChoice/choice-store";

const Vacancy = (props)=>{
    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    const selectVacancy = (event) => {
        sessionStorage.setItem('currentVacancyId', event.target.id);
    }
    let cityText = "City:";
    let customerText = "Customer:";
    let hoursText = "Hours:";
    let submittedBeforeText = "Submitted before:";


    if (nederlands) {
        cityText = "Stad:";
        customerText = "Klant:";
        hoursText = "Uren:"
        submittedBeforeText = "Inleveren voor:";
    }

    const MAXLENGTH_TITLE = 40;
    const vacancy = props.vacancy;
    return(
        <Link to="/vacancy-page/" className="vacancies-cell" key={vacancy.vacancyId} id={vacancy.vacancyId}
              onClick={selectVacancy}>
            <div className="vacancy vacancies-cell-content" id={vacancy.vacancyId} onClick={selectVacancy}>
                <div className="vacancy-text vacancy-text-title" id={vacancy.vacancyId}
                     onClick={selectVacancy}>{vacancy.name.substring(0, MAXLENGTH_TITLE)}</div>

                <label className="vacancy-label vacancy-label-final-date-submission" id={vacancy.vacancyId}
                       onClick={selectVacancy}>{submittedBeforeText}</label>
                <div className="vacancy-text vacancy-text-final-date-submission" id={vacancy.vacancyId}
                     onClick={selectVacancy}>{vacancy.final_date_submission}</div>

                <label className="vacancy-label vacancy-label-status" id={vacancy.vacancyId}
                       onClick={selectVacancy}>Status:</label>
                <div className="vacancy-text vacancy-text-status" id={vacancy.vacancyId}
                     onClick={selectVacancy}>{vacancy.status}</div>

                <label className="vacancy-label vacancy-label-city" id={vacancy.vacancyId}>{cityText}</label>
                <div className="vacancy-text vacancy-text-city" id={vacancy.vacancyId}>{vacancy.city}</div>

                <label className="vacancy-label vacancy-label-hours" id={vacancy.vacancyId}>{hoursText}</label>
                <div className="vacancy-text vacancy-text-hours" id={vacancy.vacancyId}>{vacancy.hours}</div>

                <label className="vacancy-label vacancy-label-customer" id={vacancy.vacancyId}>{customerText}</label>
                <div className="vacancy-text vacancy-text-customer" id={vacancy.vacancyId}>{vacancy.customer}</div>

            </div>
        </Link>
    )
}
export default Vacancy;