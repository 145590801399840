import Introduction from '../Introduction/Introduction';
import Package from '../Package/Package';
import Customers from '../Customers/Customers';
import {vacanciesStore} from "../Vacancies/vacancies-store";
import {useEffect, useState} from "react";
import {choiceStore} from "../LanguageChoice/choice-store";
import {Link} from "react-router-dom";
import {ntegerVacanciesStore} from '../Vacancies/nteger-vacancies-store';
import Vacancy from "../Vacancy/Vacancy";
import NTGRVacancy from "../NTGRVacancy/NTGRVacancy";

const Main = () => {
    //===========vacanciesStore ================
    const vacancies = vacanciesStore((state) => state.vacancies);
    const loadingVacancies = vacanciesStore((state) => state.loadingVacancies);
    const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    const fetchVacanciesByCustomer = vacanciesStore((state) => state.fetchVacanciesByCustomer);
    const fetchFirstThree = vacanciesStore((state) => state.fetchFirstThree);
    //=========== vacanciesStore ================

    //=========== ntegerVacanciesStore ================
    const loadingNTGRVacancies = ntegerVacanciesStore((state)=>state.loadingVacancies);
    const fetchNtegerVacancies = ntegerVacanciesStore((state) => state.fetchNtegerVacancies);
    const NtegerVacancies = ntegerVacanciesStore((state)=>state.NtegerVacancies);
    const fetchFirstThreeNtegerVacancies = ntegerVacanciesStore((state)=>state.fetchFirstThreeNtegerVacancies);
    //=========== ntegerVacanciesStore ================

    useEffect(() => {
        fetchVacancies().then(fetchFirstThree).then(fetchNtegerVacancies).then(fetchFirstThreeNtegerVacancies);
    }, [fetchVacancies, fetchFirstThree]);
    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================


    let vacanciesText = "VACANCIES";
    let NtegervacanciesText = "n-TGR VACANCIES";
    let customerText = "Some customers";
    let moreText = "More ..."
    let loadingText = "Loading vacancies ..."
    let noVacanciesText = "No vacancies at this time"

    if (nederlands) {
        vacanciesText = "Vacatures";
        NtegervacanciesText = "n-TGR VACATURES";
        customerText = "Enkele klanten";
        moreText = "Meer ..."
        loadingText = "Vacatures worden geladen ..."
        noVacanciesText = "Geen vacatures op dit moment"
    }


    //=============To prevent nasty behaviour on the telephone they have a width < 415px
    const screenWidth = window.innerWidth;
    const [port, setPort] = useState(null);
    window.matchMedia("(orientation:portrait)").addEventListener("change", e => {
        if (e.matches) {
            setPort(true);
        } else {
            setPort(false);
        }
    })
    if (port && screenWidth < 420) {
        window.location.reload();//Every time the orientation is switched from landscape to portrait this fires, not pretty!!
    }
    //=============To prevent nasty behaviour on the telephone

    const vacancyList = vacancies.map(vacancy =>
        <Vacancy vacancy = {vacancy} key={vacancy.vacancyId}/>
    );

    const NtegerVacancyList = NtegerVacancies.map(vacancy =>
        <NTGRVacancy vacancy = {vacancy} key={vacancy.vacancyId}/>
    );


    const getVacanciesByCustomer = (event) => {
        fetchVacanciesByCustomer(event.target.id).then();
    }

    const noVacancies = <p className="vacancies-no-vacancies">{noVacanciesText}</p>

    return (
        <section className="main">
            <div className="main-introduction"><Introduction/></div>
            <div className="main-package"><Package/></div>
            <div className="main-vacancies">
                <div className="main-vacancies-header">
                    {vacanciesText}
                </div>
                <div className="vacancies">
                    <div className="vacancies-container">
                        <div className="vacancies-cell">
                            <Link to="/vacancies-page" className="vacancies-cell-content vacancies-customer-button"
                                  id="none" onClick={getVacanciesByCustomer}>
                                {moreText}
                            </Link>
                        </div>
                        {loadingVacancies && <p className="vacancies-no-vacancies">{loadingText}</p>}
                        {!loadingVacancies && vacancies.length > 0 && vacancyList}
                        {!loadingVacancies && vacancies.length === 0 && noVacancies}
                    </div>
                </div>
            </div>
            <div className="main-customers">
                <div className="main-customers-header">
                    {customerText}
                </div>
                <Customers/>
            </div>
            <div className="main-nteger-vacancies">
                <div className="main-nteger-vacancies-header">
                    {NtegervacanciesText}
                </div>
                <div className="vacancies">
                    <div className="vacancies-container">
                        <div className="vacancies-cell">
                            <Link to="/ntgr-vacancies-page" className="vacancies-cell-content vacancies-customer-button">
                                {moreText}
                            </Link>
                        </div>
                        {loadingNTGRVacancies && <p className="vacancies-no-vacancies">{loadingText}</p>}
                        {!loadingNTGRVacancies && NtegerVacancies.length>0 && NtegerVacancyList}
                        {!loadingNTGRVacancies && NtegerVacancies.length===0 && noVacancies}
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Main;