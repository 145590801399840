import Vacancies from '../Vacancies/Vacancies';
import {useEffect, useState} from "react";
import {vacanciesStore} from "../Vacancies/vacancies-store";

const VacanciesPage = () => {
    //===========vacanciesStore ================
    const fetchVacanciesByCustomer = vacanciesStore((state) => state.fetchVacanciesByCustomer);
    const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    //=========== vacanciesStore ================

    //=============To prevent nasty behaviour on the telephone
    const screenWidth = window.innerWidth;
    const [port, setPort] = useState(null);
    window.matchMedia("(orientation:portrait)").addEventListener("change", e => {
        if (e.matches) {
            setPort(true);
        } else {
            setPort(false);
        }
    })
    if (port && screenWidth < 420) {
        window.location.reload();
    }
    //=============To prevent nasty behaviour on the telephone

    useEffect(() => {
        let customer = sessionStorage.getItem('customer');
        if (!customer) {
            console.log('ttest');
            fetchVacancies().then();
        } else {
            let customer = sessionStorage.getItem('customer');
            fetchVacanciesByCustomer(customer).then();
        }

    }, [fetchVacanciesByCustomer,fetchVacancies]);


    window.scrollTo(0, 0);


    return (

        <div className="vacancies-page">
            <div>{""}</div>

            <Vacancies page="VacanciesPage"/>
        </div>
    )
}
export default VacanciesPage;