import React, {Fragment} from 'react';
import ReactDOM from "react-dom";


const Backdrop = props => {
    return <div className="vacancy-response-backdrop" onClick={props.onClose}></div>
}

const ModalOverlay = props =>{
    return (<div className="vacancy-response-modal">
        <div className="vacancy-response-modal-content">{props.children}</div>
    </div>);
};

// MAKING POPUP WORK
const portalElements = document.getElementById('overlays');

const VacancyResponseModal = props => {
    return (<Fragment>
        {/*MAKING POPUP WORK the two lines below interact with the index.html where you find <div id="overlays"></div>*/}
        {ReactDOM.createPortal(<Backdrop onClose={props.onClose}/>,portalElements)}
        {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElements)}
    </Fragment>)
}
export default VacancyResponseModal;