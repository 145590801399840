const PackageContent = (props) => {

    const showPopUp=()=>{
        props.showTheExtra();
    }

    return (
        <div className="package-explanation">
            <div className="package-explanation-icon">
                <i className={props.icon}>{" "}</i>
            </div>
            <div className="package-explanation-header">{props.header1}</div>
            <div className="package-explanation-text">
                <span>{props.text}</span>
                <span className="package-explanation-text package-explanation-text-extra" onClick={showPopUp}>{props.extra_text}</span>
            </div>

        </div>
    )
}
export default PackageContent;
