import './CustomerMenuModal.css';
import {Fragment} from "react";
import ReactDom from "react-dom";

import CustomerMenuBackdrop from "../CustomerMenuBackdrop/CustomerMenuBackdrop";
import CustomerMenuModalOverlay from "../CustomerMenuModalOverlay/CustomerMenuModalOverlay";
//Anything can be shown by this modal. Just wrap the modal around the thing you want to show.

//The line below makes it work because we show it in the index.html if called.
const portalElements = document.getElementById('overlays');

const CustomerMenuModal = (props) => {
    return (
        <Fragment>
            {ReactDom.createPortal(<CustomerMenuBackdrop onClose={props.onClose}/>, portalElements)}
            {ReactDom.createPortal(<CustomerMenuModalOverlay>{props.children}</CustomerMenuModalOverlay>, portalElements)}
        </Fragment>
    )
};

export default CustomerMenuModal;