import NavigationModal from './NavigationPopup/NavigationModal/NavigationModal';
import {vacanciesStore} from '../Vacancies/vacancies-store';
import {Link} from 'react-router-dom';
import {choiceStore} from "../LanguageChoice/choice-store";
import {ntegerVacanciesStore} from '../Vacancies/nteger-vacancies-store';
import ntgrLogo from '../../assets/images/n-TGR-logo.jpg';

const Naviation = (props) => {

    //===========vacanciesStore ================
    const fetchVacancies = vacanciesStore((state) => state.fetchVacancies);
    const fetchNtgrVacacancies = ntegerVacanciesStore((state) => state.fetchNtegerVacancies);
    //=========== vacanciesStore ================

    const hideNavigation = () => {
        props.onHide();
    }

    const goToVacancies = () => {
        fetchVacancies().then();
        props.onHide();
    }

    const goToNTGRVacancies = () => {
        fetchNtgrVacacancies().then();
        props.onHide();
    }

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================

    let vacanciesText = "Vacancies";
    let NtgrVacanciesText = "Vacancies @ ";

    let joinText = "Welcome";
    let aboutText = "About";

    if(nederlands){
        vacanciesText = "Vacatures";
        NtgrVacanciesText = "Vacatures @"
        joinText = "Welkom";
        aboutText = "Over";
    }

    return (
        <NavigationModal onClose={props.onHide}>
            <ul className="navigation-list">
                <li>
                    <Link className="navigation-list-item" to="/" onClick={hideNavigation}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link className="navigation-list-item" to="/vacancies-page" onClick={goToVacancies}>
                        {vacanciesText}
                    </Link>
                </li>
                <li>
                    <Link className="navigation-list-item" to="/ntgr-vacancies-page" onClick={hideNavigation}>
                        {NtgrVacanciesText}
                        <img src={ntgrLogo} className="navigation-list-ntgr-logo"/>
                    </Link>
                </li>
                <li onClick={hideNavigation}>
                    <Link className="navigation-list-item" to="/join-us-page" onClick={hideNavigation}>
                        {joinText}
                    </Link>
                </li>
                <li onClick={hideNavigation}>
                    <Link className="navigation-list-item" to="/about-us-page" onClick={hideNavigation}>
                        {aboutText}
                    </Link>
                </li>
                <li onClick={hideNavigation}>
                    <Link className="navigation-list-item" to="/contact-page" onClick={hideNavigation}>
                        Contact
                    </Link>
                </li>


            </ul>
        </NavigationModal>
    );
}
export default Naviation;