import {choiceStore} from "../LanguageChoice/choice-store";

const HeaderCenter = () => {

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================

    let integrityText = "Integrity";
    let strengthText = "Your freedom our strength";

    if(nederlands){
        integrityText = "Integriteit";
        strengthText = "Jouw vrijheid is onze kracht";
    }



    return(
        <div className="header-center">
            <span className="header-center-main">{integrityText}</span>
            <span className="header-center-sub">{strengthText}</span>
        </div>)
}
export default HeaderCenter;