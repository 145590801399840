const JoinUsProcessHeaderCel = (props) => {

    const onClickCel = (event) => {
        props.onClickCel(event.target.id);
    }

    const classNameCel = "join-us-process-header-cel " + props.classNameCel;
    const classNameIcon = "join-us-process-header-icon " + props.icon;

    return (
        <div className={classNameCel} id={props.id} onClick={onClickCel}>
            <i className={classNameIcon} id={props.id} onClick={onClickCel}>{" "}</i>
            <div className="join-us-process-header-text" id={props.id} onClick={onClickCel}>
                {props.text}
            </div>
        </div>
    )
}
export default JoinUsProcessHeaderCel;