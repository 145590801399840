import {Link} from "react-router-dom";
import {choiceStore} from "../LanguageChoice/choice-store";
const ApplyForJob = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const downloadFileAtURL = (url) => {
        const CV_TEMPLATE = baseURL + '/CV_TEMPLATE_NED.docx';
        fetch(CV_TEMPLATE).then(response => response.blob()).then(blob => {
            const blobURL = window.URL.createObjectURL(new Blob([blob]));
            const fileName = CV_TEMPLATE.split("/").pop();
            const aTag = document.createElement('a');
            aTag.href = blobURL;
            aTag.setAttribute("download", fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
        })
    }

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================


    const applyContent = [
        {
            icon: "",
            english: {
                header1: "",
                text: "how to apply for the job you  picked",
                extra_text: ""
            },
            dutch: {
                header1: "",
                text: "solliciteren op gekozen vacature",
                extra_text: ""
            }
        },
        {
            icon: "fa-thin fa-people-line",
            to: "",
            english: {
                header: "Step 1",
                text: "At the bottom of any selected vacancy you find an apply button. We only need you name, emailaddress and telephone number.",
                text2: ""
            },
            dutch: {
                header: "Stap 1",
                text: "Onderaan elke vacature is een solliciteer knop. Het enige wat we nodig hebben is je naam, email en telefoon nummer.",
                text2: ""
            }
        },
        {
            icon: "fa-thin fa-laptop-code",
            english: {
                header: "Step 2",
                text:  "We will need your current CV in our Template. Please ",
                text2: "download ",
                text3: "and return it via info@n-tgr.nl"
            },
            dutch: {
                header: "Stap 2",
                text:  "We hebben je laatste CV nodig in onze Template. Download ",
                text2: "deze ",
                text3: "en retourneer hem naar info@n-tgr.nl"
            }
        },
        {
            icon: "fa-thin fa-handshake",
            to: "/apply-for-job",
            english: {
                header: "Step 3",
                text: "Then we sit together to make your CV ready for submission to the customer. It is vital the CV is a good match for the vacancy",
                text2: "",
                text3: ""
            },
            dutch: {
                header: "Stap 3",
                text: "Samen maken we je CV geschikt voor gebruik bij de klant. Het is van vitaal belang dat je CV een goede match is met de vacature",
                text2: "",
                text3: ""
            }
        },
        {
            icon: "fa-thin fa-file-contract",
            english: {
                header: "Step 4",
                text: "Once we have a suitable CV and you are sure you want the job, you tell us the minimum hourly rate and we will submit your application."
            },
            dutch: {
                header: "Stap 4",
                text: "Zodra we een geschikt CV hebben en je zeker bent dat je de klus wilt, vertel je hoeveel uur en tegen welk tarief en wij handelen de sollicitatie af."
            }
        }]

    return (
        <div className="apply-for-job">
            {!nederlands &&
            <div className="apply-for-job-content">

                <div className="apply-for-job-content-steps">
                    <div className="apply-for-job-header">{applyContent[0].english.text}</div>

                    <div className="apply-for-job-step">{applyContent[1].english.header}</div>
                    <div className="apply-for-job-cell">{applyContent[1].english.text}</div>

                    <div className="apply-for-job-step">{applyContent[2].english.header}</div>
                    <div className="apply-for-job-cell">
                        <span>{applyContent[2].english.text}</span>
                        <Link className="apply-for-job-content-download" onClick={downloadFileAtURL}>{applyContent[2].english.text2}</Link>
                        <span>{applyContent[2].english.text3}</span>
                    </div>

                    <div className="apply-for-job-step">{applyContent[3].english.header}</div>
                    <div className="apply-for-job-cell">{applyContent[3].english.text}</div>

                    <div className="apply-for-job-step">{applyContent[4].english.header}</div>
                    <div className="apply-for-job-cell">{applyContent[4].english.text}</div>

                </div>
            </div>
            }
            {nederlands &&
                <div className="apply-for-job-content">

                    <div className="apply-for-job-content-steps">
                        <div className="apply-for-job-header">{applyContent[0].dutch.text}</div>

                        <div className="apply-for-job-step">{applyContent[1].dutch.header}</div>
                        <div className="apply-for-job-cell">{applyContent[1].dutch.text}</div>

                        <div className="apply-for-job-step">{applyContent[2].dutch.header}</div>
                        <div className="apply-for-job-cell">
                            <span>{applyContent[2].dutch.text}</span>
                            <Link className="apply-for-job-content-download" onClick={downloadFileAtURL}>{applyContent[2].dutch.text2}</Link>
                            <span>{applyContent[2].dutch.text3}</span>
                        </div>

                        <div className="apply-for-job-step">{applyContent[3].dutch.header}</div>
                        <div className="apply-for-job-cell">{applyContent[3].dutch.text}</div>

                        <div className="apply-for-job-step">{applyContent[4].dutch.header}</div>
                        <div className="apply-for-job-cell">{applyContent[4].dutch.text}</div>

                    </div>
                </div>
            }
        </div>
    )
}
export default ApplyForJob;