// import Response from '../Response/Response';
// import {Link} from "react-router-dom";
import SalaryExample from '../SalaryExample/SalaryExample';
import {useState} from "react";
import JoinUsContent from './JoinUsContent';
import JoinUsProcessHeaderCel from './JoinUsProcessHeaderCel';
import {choiceStore} from "../LanguageChoice/choice-store";

const JoinUs = () => {
    const [joinId, setJoinId] = useState(1);
    window.scrollTo(0, 0);
    const [salaryExampleVisable, setSalaryExampleVisable] = useState(false);
    const showSalaryExample = () => {
        setSalaryExampleVisable(true);
    }
    const hideSalaryExample = () => {
        setSalaryExampleVisable(false);
    }

    const handleJoinUsEvent = (input) => {
        setJoinId(input);
        console.log('handleJoinUsEvent:' + input);
    }

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================

    let headerText = "let's start to colLaborate";
    if(nederlands){
        headerText = "laten we gaan samenwerken";
    }


    const joinUsContent = [
        {
            icon: "",
            english: {
                header1: "",
                text: "",
                extra_text: ""
            }
        },
        {
            icon: "fa-thin fa-people-line",
            to: "/contact-page",
            english: {
                header: ">  INTRO",
                text: " Is our approach and package appealing? Then let's have an on line session. You can apply for one via  ",
                extra_text: "contact form."
            },
            dutch: {
                header: ">  INTRO",
                text: " Vind je de aanpak en het pakket aantrekkelijk? Laten we dan kennismaken. Je kunt je hiervoor aanmelden via het ",
                extra_text: "contact formulier."
            }
        },
        {
            icon: "fa-thin fa-laptop-code",
            to: "/vacancies-page",
            english: {
                header: ">  CHOOSE",
                text: "We deliver direct to a number of customers that share their vacancies with us. Choose from the vacancies listed ",
                extra_text: "here."
            },
            dutch: {
                header: ">  KIES",
                text: "Wij leveren direct aan klanten die hun vacatures met ons delen. Kies een vacature uit de lijst op deze  ",
                extra_text: "pagina."
            }
        },
        {
            icon: "fa-thin fa-handshake",
            to: "/apply-for-job",
            english: {
                header: ">  APPLY",
                text: "Together we will apply for the job. Make sure you read the last day of submission. You deliver a great CV, we will handle the rest."
            },
            dutch: {
                header: ">  SOLLICITEER",
                text: "We solliciteren samen. Lees de uiterlijke inlever datum. Jij levert een geweldig CV, wij doen de rest."
            }
        },
        {
            icon: "fa-thin fa-file-contract", english: {
                header: ">  JOIN",
                text: "Once you have landed your first job, you come on board on a permanent contract. Click the icon to get an idea of the salary."
            },
            dutch: {
                header: ">  SLUIT AAN",
                text: "Zodra je een eerste klus hebt, kom je aan boord op een permanent contract. Klik op het icoon om een idee te krijgen van je salaris  "
            }
        }]


    return (
        <div className="join-us">
            <div className="join-us-header">{headerText}</div>

            {!nederlands &&
                <div className="join-us-process">
                    <div className="join-us-process-header">
                        {joinId != 1 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="1"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[1].icon}
                                text={joinUsContent[1].english.header}
                            />
                        }
                        {joinId == 1 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="1"
                                classNameCel="join-us-one-box-shadow-right"
                                icon={joinUsContent[1].icon}
                                text={joinUsContent[1].english.header}
                            />
                        }

                        {joinId != 2 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="2"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[2].icon}
                                text={joinUsContent[2].english.header}
                            />
                        }
                        {joinId == 2 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="2"
                                classNameCel="join-us-two-box-shadow"
                                icon={joinUsContent[2].icon}
                                text={joinUsContent[2].english.header}
                            />
                        }

                        {joinId != 3 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="3"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[3].icon}
                                text={joinUsContent[3].english.header}
                            />
                        }
                        {joinId == 3 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="3"
                                classNameCel="join-us-two-box-shadow"
                                icon={joinUsContent[3].icon}
                                text={joinUsContent[3].english.header}
                            />
                        }

                        {joinId != 4 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="4"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[4].icon}
                                text={joinUsContent[4].english.header}
                            />

                        }
                        {joinId == 4 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="4"
                                classNameCel="join-us-one-box-shadow-left"
                                icon={joinUsContent[4].icon}
                                text={joinUsContent[4].english.header}
                            />
                        }
                    </div>
                    <div className="join-us-process-explanation">
                        <JoinUsContent
                            joinId={joinId}
                            text={joinUsContent[joinId].english.text}
                            extra={joinUsContent[joinId].english.extra_text}
                            icon={joinUsContent[joinId].icon}
                            to={joinUsContent[joinId].to}
                            showSalaryExample={showSalaryExample}
                        />
                    </div>

                </div>
            }
            {nederlands &&
                <div className="join-us-process">
                    <div className="join-us-process-header">
                        {joinId != 1 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="1"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[1].icon}
                                text={joinUsContent[1].dutch.header}
                            />
                        }
                        {joinId == 1 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="1"
                                classNameCel="join-us-one-box-shadow-right"
                                icon={joinUsContent[1].icon}
                                text={joinUsContent[1].dutch.header}
                            />
                        }

                        {joinId != 2 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="2"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[2].icon}
                                text={joinUsContent[2].dutch.header}
                            />
                        }
                        {joinId == 2 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="2"
                                classNameCel="join-us-two-box-shadow"
                                icon={joinUsContent[2].icon}
                                text={joinUsContent[2].dutch.header}
                            />
                        }

                        {joinId != 3 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="3"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[3].icon}
                                text={joinUsContent[3].dutch.header}
                            />
                        }
                        {joinId == 3 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="3"
                                classNameCel="join-us-two-box-shadow"
                                icon={joinUsContent[3].icon}
                                text={joinUsContent[3].dutch.header}
                            />
                        }

                        {joinId != 4 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="4"
                                classNameCel="join-us-process-header-cel-grey"
                                icon={joinUsContent[4].icon}
                                text={joinUsContent[4].dutch.header}
                            />

                        }
                        {joinId == 4 &&
                            <JoinUsProcessHeaderCel
                                onClickCel={handleJoinUsEvent}
                                id="4"
                                classNameCel="join-us-one-box-shadow-left"
                                icon={joinUsContent[4].icon}
                                text={joinUsContent[4].dutch.header}
                            />
                        }
                    </div>
                    <div className="join-us-process-explanation">
                        <JoinUsContent
                            joinId={joinId}
                            text={joinUsContent[joinId].dutch.text}
                            extra={joinUsContent[joinId].dutch.extra_text}
                            icon={joinUsContent[joinId].icon}
                            to={joinUsContent[joinId].to}
                            showSalaryExample={showSalaryExample}
                        />
                    </div>

                </div>
            }
            {salaryExampleVisable && <SalaryExample onHide={hideSalaryExample}/>}
        </div>);
}
export default JoinUs;