import {create} from "zustand";

const choiceStore = create((set) => ({
    nederlands:false,
    setNederlands: (input) => {
        set(state => ({nederlands: input}));
        localStorage.setItem('Language',input);
    },
}));

export {choiceStore};