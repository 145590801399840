import './CookiesAlertModalOverlay.css';

const CookiesAlertModalOverlay = props=>{
    return(
        <div className="cookies-alert-modal-overlay">
            <div className="content">{props.children}</div>
        </div>
    )
};

export default CookiesAlertModalOverlay;