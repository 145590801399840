import {useImmer} from "use-immer";
import {useState, useEffect} from "react";
import {emailStore} from "./email-store";
import ResponseInput from '../ResponseInput/ResponseInput';
import {choiceStore} from "../LanguageChoice/choice-store";

const Response = (props) => {

    // ================= EmailStore=====================
    const sendEmail = emailStore((state) => state.sendEmail);
    const emailResponse = emailStore((state) => state.emailResponse);
    const emailHasErrors = emailStore((state) => state.emailHasErrors);
    //================= EmailStore=====================
    // console.log('Response is called by: ' + props.parent);

    const [vacancyPage, setVacancyPage] = useState(false);
    const [contactPage, setContactPage] = useState(false);


    const initialEmail = {
        name: "",
        email: "",
        phone: "",
        message: "",
        about: ""
    }

    const [email, setEmail] = useImmer(initialEmail);

    const [nameHasError, SetNameHasError] = useState(null);
    const [emailHasError, SetEmailHasError] = useState(null);
    const [phoneHasError, SetPhoneHasError] = useState(null);
    const [formIsFilled, setFormIsFilled] = useState(false);

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    let placeHolderName = "Name*";
    let placeHolderMail = "Email Address*";
    let placeHolderPhone = "Phone Number*";
    let placeHolderMessage = "Send us a message";

    if(nederlands) {
        placeHolderName = "Naam *";
        placeHolderMail = "Email Adres*";
        placeHolderPhone = "Telefoonnummer*";
        placeHolderMessage = "Stuur ons een bericht";
    }



    //=====================Begin Change Handlers==========================//
    const nameChangeHandler = (event) => {
        setEmail(draft => {
            draft.name = event.target.value;
        });
        SetNameHasError(false);
    }

    const emailChangeHandler = (event) => {
        setEmail(draft => {
            draft.email = event.target.value;
        });
        SetEmailHasError(false);
    }

    const phoneChangeHandler = (event) => {
        setEmail(draft => {
            draft.phone = event.target.value;
        });
        SetPhoneHasError(false);
        //We need to fill the about with the actual vacancy and vacancy_id
        setEmail(draft => {
            draft.about = props.about;
        });
    }

    const messageChangeHandler = (event) => {
        setEmail(draft => {
            draft.message = event.target.value;
        });
    }

    //=====================End Change Handlers==========================//

    //=========================Begin BlurHandlers======================//

    const nameBlurHandler = () => {
        if (email.name === '') SetNameHasError(true);
    }

    const emailBlurHandler = () => {
        if (email.email === '') SetEmailHasError(true);
    }

    const phoneBlurHandler = () => {
        if (email.phone === '') SetPhoneHasError(true);
    }
    //=========================End BlurHandlers======================//

    //=========================Begin Submission handling======================//


    const checkIfFormIsFilled = () => {//All fields must have a value
        // console.log('checkIfFormIsFilled');
        if (
            email.name !== '' &&
            email.email !== '' &&
            email.phone !== ''
        ) {
            setFormIsFilled(true);
        } else (setFormIsFilled(false));
    }
    useEffect(() => {
        //This part fires and uses the CURRENT state not the former one!!
        checkIfFormIsFilled();
    }, [email]);

    useEffect(() => {
        if (props.parent === 'vacancy-response') {
            setVacancyPage(true);
        }

        if (props.parent === 'contact-page') {
            setContactPage(true);
        }
    }, [props.parent]);

    // console.log('vacancyPage: ' + vacancyPage);
    // console.log('contactPage: ' + contactPage);


    const handleResult = () => {
        // console.log('emailResponse: ' + emailResponse);
        // console.log('emailHasErrors: ' + emailHasErrors);
        if (!emailHasErrors) {
            // alert('Your messagge is sent to info@n-tgr.nl');
            props.hideResponse();
        } else {
            alert('Something is wrong please try again later');
        }


    }

    const handleSubmit = () => {
        const result = sendEmail(email).then(handleResult());
    }

    //=========================End Submission handling======================//

    return (
        <div>
            {contactPage &&
                <div className="response">
                    <div className="response-part">
                        <div className="response-part-header">
                            {props.formHeader}
                        </div>
                    </div>

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderName}
                        value={email.name}
                        changeHandler={nameChangeHandler}
                        blurHandler={nameBlurHandler}
                        error={nameHasError}
                        errorMessage="Name must be entered"
                        className="response-input"
                        responsePart="response-part"
                    />

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderMail}
                        value={email.email}
                        changeHandler={emailChangeHandler}
                        blurHandler={emailBlurHandler}
                        error={emailHasError}
                        errorMessage="Email must be entered"
                        className="response-input"
                        responsePart="response-part"
                    />

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderPhone}
                        value={email.phone}
                        changeHandler={phoneChangeHandler}
                        blurHandler={phoneBlurHandler}
                        error={phoneHasError}
                        errorMessage="Phone Number must be entered"
                        className="response-input"
                        responsePart="response-part"
                    />

                    <div className="response-part ">
                        <textarea placeholder={placeHolderMessage}
                                  className="response-part-message"
                                  onChange={messageChangeHandler}
                                  value={email.message}
                        />
                    </div>
                    <div className="response-part response-part-submit">
                        <button disabled={!formIsFilled} className="btn response-submit" onClick={handleSubmit}>
                            {props.formSubmitText}
                        </button>
                    </div>
                </div>
            }
            {vacancyPage &&
                <div className="response">
                    <div className="response-part-vacancy">
                        <div className="response-part-vacancy-header">
                            {props.formHeader}
                        </div>
                    </div>

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderName}
                        value={email.name}
                        changeHandler={nameChangeHandler}
                        blurHandler={nameBlurHandler}
                        error={nameHasError}
                        errorMessage="Name must be entered"
                        className="response-input-vacancy"
                        responsePart="response-part-vacancy"
                    />

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderMail}
                        value={email.email}
                        changeHandler={emailChangeHandler}
                        blurHandler={emailBlurHandler}
                        error={emailHasError}
                        errorMessage="Email must be entered"
                        className="response-input-vacancy"
                        responsePart="response-part-vacancy"
                    />

                    <ResponseInput
                        type="text"
                        placeHolder={placeHolderPhone}
                        value={email.phone}
                        changeHandler={phoneChangeHandler}
                        blurHandler={phoneBlurHandler}
                        error={phoneHasError}
                        errorMessage="Phone Number must be entered"
                        className="response-input-vacancy"
                        responsePart="response-part-vacancy"
                    />

                    <div className="response-part-vacancy ">
                        <textarea placeholder={placeHolderMessage}
                                  className="response-part-vacancy-message"
                                  onChange={messageChangeHandler}
                                  value={email.message}
                        />
                    </div>
                    <div className="response-part-vacancy response-part-vacancy-submit">
                        <button disabled={!formIsFilled} className="btn response-submit" onClick={handleSubmit}>
                            {props.formSubmitText}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}
export default Response;