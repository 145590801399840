import {useState, useRef, useEffect, useCallback} from "react";
import {Link} from 'react-router-dom';
import {vacanciesStore} from "../Vacancies/vacancies-store";

const NTGRSlider = (props) => {

    const timeRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = props.logos;

    //===========vacanciesStore ================
    const fetchVacanciesByCustomer = vacanciesStore((state) => state.fetchVacanciesByCustomer);
    //=========== vacanciesStore ================

    const getVacanciesByCustomer = (event) => {
        console.log('input: ' + event.target.id);
        fetchVacanciesByCustomer(event.target.id).then();
        // props.onHide();
    }
    const slides = images.map((image, imageIndex) => (
        <Link to="/vacancies-page/" className="slide-image-container" key={imageIndex} id={image.title}>
            {imageIndex === currentSlide &&
                <img src={image.url} alt={image.title} className="slide-image" id={image.title}
                     onClick={getVacanciesByCustomer}/>}
        </Link>
    ));

    const prevSlide = () => {
        //If 0 then go to the last one = numberOfSlides -1. Otherwise, go to the previous.
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
    }

    const nextSlide = () => {
        //If at the end, go to the first, otherwise go to the next.
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }

    useEffect(() => {
        if (timeRef.current) {
            clearTimeout(timeRef.current);
        }
        timeRef.current = setTimeout(() => {
            nextSlide();
        }, 2000)
    }, [nextSlide])

    return (
        <section className="slider">
            <div className="arrow-slide-container-left">
                <i className="fa-light fa-angle-left arrow-slide arrow-slide-left" onClick={prevSlide}>{" "}</i>
            </div>
            {slides}
            <div className="arrow-slide-container-right">
                <i className="fa-light fa-angle-right arrow-slide arrow-slide-right" onClick={nextSlide}>{" "}</i>
            </div>
        </section>
    )
}
export default NTGRSlider;