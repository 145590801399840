import './CustomerMenuModalOverlay.css';

const CustomerMenuModalOverlay = props=>{
    return(
        <div className="customer-menu-modal-overlay">
            <div className="content">{props.children}</div>
        </div>
    )
};

export default CustomerMenuModalOverlay;