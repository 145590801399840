import CookiesAlertModal from './CookiesAlertPopup/CookiesAlertModal/CookiesAlertModal';
import {choiceStore} from "../LanguageChoice/choice-store";

const CookiesAlert = (props) => {

    //===========choiceStore ================
    const nederlands = choiceStore((state)=>state.nederlands)
    //===========choiceStore ================

    let cookiesText = "We use cookies to personalize our website";
    let acceptText = "Accept";
    let declineText = "Decline";

    if(nederlands){
        cookiesText = "We gebruiken cookies om de website te personalizeren";
        acceptText = "Accepteer";
        declineText = "Wijs af";
    }

    const hideCookiesAlert = () => {
        props.onHide();
    }

    return (
        <CookiesAlertModal>

            <div className="cookies-alert">
                <div className="cookies-alert-text">{cookiesText}</div>
                <div className="cookies-alert-btn-cell">
                    <div className="cookies-alert-btn-box">
                        <div className="btn cookies-alert-btn cookies-alert-ok" onClick={hideCookiesAlert}>{acceptText}</div>
                        <div className="btn cookies-alert-btn cookies-alert-no" onClick={hideCookiesAlert}>{declineText}</div>
                    </div>
                </div>
            </div>
        </CookiesAlertModal>
    );
}
export default CookiesAlert;