import Response from '../Response/Response';
import {useState} from "react";
import Contact from '../Contact/Contact';
import {choiceStore} from "../LanguageChoice/choice-store";


const ContactPage = () => {

    window.scrollTo(0, 0);
    const onResponse = () => {
        console.log('mail sent');
    }

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    let interestedText = "If you are interested in focussing on your craftmanship then you should definitely get in touch with us.";
    let hereText = "Here";
    let contactText = "are all our contact details. We will get back to you in less then 24 hours. That is a promise!";
    let submitText = "Send us a message";
    let thoughtsText = "Share your thoughts";

    if(nederlands) {
        interestedText = "Als je geïnteresseerd ben in focussen op je vakmensschap dan moet je zeker met ons in gesprek.";
        hereText = "Hier";
        contactText = "zijn al onze contact details. We komen er binnen 24 hours op terug. Dat is een belofte!";
        submitText = "Stuur ons een bericht";
        thoughtsText = "Deel je gedachten";
    }

    const [contactVisable, setContactVisable] = useState(false);
    const showContact = () => {
        setContactVisable(true);
    }
    const hideContact = () => {
        setContactVisable(false);
    }

    console.log(contactVisable);
    return (
        <div className="contact-page">
            <div className="contact-page-content">
                <div className="contact-page-left">
                    <Response
                        formSubmitText={submitText}
                        formHeader={thoughtsText}
                        about="Contact response"
                        hideResponse={onResponse}
                        parent="contact-page"
                    />
                </div>
                <div className="contact-page-right">
                    <div className="contact-page-right-content">
                        {interestedText}
                                <a href="#footer" className="contact-page-right-content-footerhook" onClick={showContact}> {hereText} </a>
                        {contactText}
                    </div>
                </div>
            </div>
            {contactVisable && <Contact onHide={hideContact}/>}
        </div>
    );
}
export default ContactPage;