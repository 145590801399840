import {Link} from "react-router-dom";
import {choiceStore} from "../LanguageChoice/choice-store";

const NTGRVacancy = (props)=>{
    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    const selectVacancy = (event) => {
        sessionStorage.setItem('currentVacancyId', event.target.id);
    }
    let cityText = "City:";

    if (nederlands) {
        cityText = "Stad:";
    }

    const MAXLENGTH_TITLE = 40;
    const vacancy = props.vacancy;
    return(
        <Link to="/ntgr-vacancy-page/" className="vacancies-cell" key={vacancy.vacancyId} id={vacancy.vacancyId}
              onClick={selectVacancy}>
            <div className="ntgr-vacancy vacancies-cell-content" id={vacancy.vacancyId} onClick={selectVacancy}>
                <div className="ntgr-vacancy-text ntgr-vacancy-text-title" id={vacancy.vacancyId}
                     onClick={selectVacancy}>{vacancy.name.substring(0, MAXLENGTH_TITLE)}</div>

                <label className="ntgr-vacancy-label ntgr-vacancy-label-status" id={vacancy.vacancyId}
                       onClick={selectVacancy}>Status:</label>
                <div className="ntgr-vacancy-text ntgr-vacancy-text-status" id={vacancy.vacancyId}
                     onClick={selectVacancy}>{vacancy.status}</div>

                <label className="ntgr-vacancy-label ntgr-vacancy-label-city" id={vacancy.vacancyId}>{cityText}</label>
                <div className="ntgr-vacancy-text ntgr-vacancy-text-city" id={vacancy.vacancyId}>{vacancy.city}</div>

            </div>
        </Link>
    )
}
export default NTGRVacancy;