import './NavigationModalOverlay.css';

const NavigationModalOverlay = props=>{
    return(
        <div className="navigation-modal-overlay">
            <div className="content">{props.children}</div>
        </div>
    )
};

export default NavigationModalOverlay;