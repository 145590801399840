import {Link} from 'react-router-dom';

const JoinUsContent = (props) => {
    return (
        <div className="join-us-content">
            <div className="join-us-content-text">
                            <span>{props.text}
                                <Link className="join-us-content-text-link"
                                      to={props.to}>{props.extra}
                                </Link>
                            </span>
            </div>
                {props.joinId != 4 &&
                    <Link to={props.to} className="join-us-content-icon">
                        <i className={props.icon}>{" "}</i>
                    </Link>
                }
                {props.joinId == 4 &&
                    <div className="join-us-content-icon" onClick={props.showSalaryExample}>
                        <i className={props.icon}>{" "}</i>
                    </div>
                }
        </div>
    )
}
export default JoinUsContent;