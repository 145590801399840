// import Modal from "../Popup/Modal/Modal";
import VacancyResponseModal from './VacancyResponsePopup/VacancyResponseModal';
import Response from '../Response/Response';

const VacancyResponse = (props) => {

    const currentVacancy = props.currentVacancy;
    const about = "Vacancy: " + currentVacancy.name + " Customer Vacancy Id: " + currentVacancy.customer_vacancy_id;

    return (

        <VacancyResponseModal onClose={props.hideVacancyResponse}>
            <Response
                formSubmitText="Submit"
                formHeader={currentVacancy.name}
                about={about}
                hideResponse={props.hideVacancyResponse}
                parent="vacancy-response"
            />
        </VacancyResponseModal>
    )
}
export default VacancyResponse;