const ResponseInput = (props) => {

    const error = props.error;
    let placeHolder = props.placeHolder;
    let className = props.className;

    if (error) {
        placeHolder = props.errorMessage;
        className = "response-input-error"
    }


    return (
        <div className={props.responsePart}>
            <input
                type="text"
                placeholder={placeHolder}
                value={props.value}
                className={props.className}
                onChange={props.changeHandler}
                onBlur={props.blurHandler}
            />
        </div>
    )
}
export default ResponseInput;