import './CookiesAlertModal.css';
import {Fragment} from "react";
import ReactDom from "react-dom";

import CookiesAlertBackdrop from "../CookiesAlertBackdrop/CookiesAlertBackdrop";
import CookiesAlertModalOverlay from "../CookiesAlertModalOverlay/CookiesAlertModalOverlay";
//Anything can be shown by this modal. Just wrap the modal around the thing you want to show.

//The line below makes it work because we show it in the index.html if called.
const portalElements = document.getElementById('overlays');

const CookiesAlertModal = (props) => {
    return (
        <Fragment>
            {ReactDom.createPortal(<CookiesAlertBackdrop/>, portalElements)}
            {ReactDom.createPortal(<CookiesAlertModalOverlay>{props.children}</CookiesAlertModalOverlay>, portalElements)}
        </Fragment>
    )
};

export default CookiesAlertModal;