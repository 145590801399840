import {create} from 'zustand';


const baseURL = process.env.REACT_APP_BASE_URL_VACANCIES;//The base url can be found in the .env files
//To test on the Mac
//REACT_APP_BASE_URL_VAC=http://localhost:8085/vacancyservice/

const initialVacancy = {
    vacancyId: '',
    name: '',
    description: '',
    education: '',
    end_date: '',
    start_date: '',
    function_name: '',
    hours: '',//Don't think any different
    city: '',
    option_to_extend: '',
    published: '',
    requirements: '',
    status: '',
    identifier: null,
    customer: '',
    customer_vacancy_id: '',
    final_date_submission: ''
};

const vacanciesStore = create((set) => ({
    // ===============Vacancies================
    listIsUpdated: false,
    vacancies: [],
    loadingVacancies: false,
    vacanciesHasErrors: false,
    subSetCustomers: [],
    loadingSubSetCustomers: false,
    subSetCustomersHasErrors: false,

    fetchVacancies: async () => {
        sessionStorage.setItem('customer', "none");
        if (!sessionStorage.getItem('Vacancies')) {//get all vacancies
            console.log('not from cache');
            set(() => ({loadingVacancies: true}));
            try {
                const response = await fetch(baseURL + "get_all_active", {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                );
                const result = await response.json();
                sessionStorage.setItem('Vacancies', JSON.stringify(result.content));
                set((state) => ({
                    data: (state.vacancies = JSON.parse(sessionStorage.getItem('Vacancies'))),
                    loadingVacancies: false
                }));
            } catch (err) {
                set(() => ({vacanciesHasErrors: true, loadingVacancies: false}));
            }
        } else {
            console.log('from cache');
            set((state) => ({
                data: (state.vacancies = JSON.parse(sessionStorage.getItem('Vacancies'))),
                loadingVacancies: false
            }));
        }
    },
    fetchVacanciesByCustomer: async (input) => {
        sessionStorage.setItem('customer', input);
        // console.log('input:' + input);
        let subSetCustomer = [];
        let cacheVacancies = JSON.parse(sessionStorage.getItem('Vacancies'));
        if (input === 'none') {
            for (let item of cacheVacancies) {
                if (item.customer !== "Nteger")
                    subSetCustomer.push(item);
            }
        } else {
            for (let item of cacheVacancies) {
                if (item.customer === input) {
                    subSetCustomer.push(item);
                }
            }
        }

        set((state) => ({loadingVacancies: true}));
        try {
            set((state) => ({data: (state.vacancies = subSetCustomer), loadingVacancies: false}));
        } catch (err) {
            set(() => ({vacanciesHasErrors: true, loadingVacancies: false}));
        }
    },
    fetchFirstThree: async () => {
        let subSet = [];
        let cacheVacancies = JSON.parse(sessionStorage.getItem('Vacancies'));

        let i = 0;
        for (let item of cacheVacancies) {
            if(item.customer !== "Nteger") {
                subSet.push(item);
                i = i + 1;
            }
            if(i == 3) {
                break;
            }
        }

        set((state) => ({loadingVacancies: true}));
        try {
            set((state) => ({data: (state.vacancies = subSet), loadingVacancies: false}));
        } catch (err) {
            set(() => ({vacanciesHasErrors: true, loadingVacancies: false}));
        }
    },
    // ===============Vacancies================

    // ===================Customers
    fetchCustomersWithVacancies: async (input) => {

        if (!sessionStorage.getItem('CustomersWithVacancies')) {
            set(() => ({loadingSubSetCustomers: true}));

            try {
                const response = await fetch(baseURL + 'get_all_active_customers', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                );
                const result = await response.json();
                let subSet = [];
                for (let item of result) {
                    if (item !== "Nteger") {
                        subSet.push(item);
                    }
                }
                // sessionStorage.setItem('CustomersWithVacancies', JSON.stringify(result));
                sessionStorage.setItem('CustomersWithVacancies', JSON.stringify(subSet));
                set((state) => ({data: (state.subSetCustomers = subSet), loadingSubSetCustomers: false}));
            } catch (err) {
                set(() => ({subSetCustomersHasErrors: true, loadingSubSetCustomers: false}));
            }
        } else {
            console.log('loadingSubSetCustomers:');
            set((state) => ({
                data: (state.subSetCustomers = JSON.parse(sessionStorage.getItem('CustomersWithVacancies'))),
                loadingVacancies: false
            }));
        }
    },
    // ===================Customers

    // ===============Vacancy================
    loadingVacancy: false,
    vacancy: initialVacancy,
    // initializeUpdate: () => set((state) => ({vacancy: initialVacancy})),
    // vacancyHasErrors: false,
    fetchVacancy: async (input) => {
        sessionStorage.setItem('currentVacancyId', input);
        let cacheVacancies = JSON.parse(sessionStorage.getItem('Vacancies'));
        // console.log('input:' + input);
        let vacancyToGet = cacheVacancies.find(val => val.vacancyId === input);
        // console.log(vacancyToGet);
        set((state) => ({data: (state.vacancy = vacancyToGet)}));
    }
    // ===============Vacancy================
}));

export {vacanciesStore};