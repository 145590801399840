import {create} from 'zustand';

const baseURL = process.env.REACT_APP_BASE_URL_MAIL + 'send/contact';//This BASE_URL can be found in the .env files



const emailStore = create((set) => ({
    // ===============Email================
    emailResponse:null,
    emailHasErrors: false,
    sendEmail: async (input) => {
        let errorMsg = {};
        try {
            const response = await fetch(baseURL, {
                    method: 'POST',
                    body: JSON.stringify(input),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    }
                }
            );
            console.log(response);
            set((state) => ({data: (state.emailResponse = response)}));
            if (!response.ok) {
                alert('Something is wrong try again later or send a mail to info@n-tgr.nl');
                errorMsg = await response.json();
                throw new Error(errorMsg);
            }else{
                alert('Your messagge is sent to info@n-tgr.nl');
            }
        } catch (err) {
            set(() => ({emailHasErrors: true, loadingVacancies: false}));
            set((state)=>({data:(state.emailHasErrors = true)}));
        }
    },
    
}));

export {emailStore};