import {create} from 'zustand';

//To test on the Mac
const baseURL = process.env.REACT_APP_BASE_URL_CUSTOMERS;


const initialCustomer = {
    customerId: '',
    customerName: '',
    customerCOCNumber: '',
    customerURL: '',
    customerLocation: ''
};

const customersStore = create((set) => ({
    // ===============Customers================
    customersAreUpdated: false,
    setCustomersAreUpdated: (input) => set(state => ({customersAreUpdated: input})),
    customers: [initialCustomer],
    loadingCustomers: false,
    customersHasError: false,
    fetchCustomers: async () => {
        set(() => ({loadingCustomers: true}));
        try {
            const response = await fetch(baseURL + "get_all", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const result = await response.json();
            // console.log(result);
            set((state) => ({data: (state.customers = result), loadingCustomers: false}));
        } catch (error) {
            set(() => ({customersHasErrors: true, loadingCustomers: false}));
        }
    },
    // ===============Customers================

    // ===============Customer================
    loadingCustomer : false,
    customer: initialCustomer,
    initializeUpdate: () => set((state) => ({customer: initialCustomer})),
    customerHasError: false,
    fetchCustomer: async (input) => {
        set(()=>({loadingCustomer:true}));
        try{
            const response = await fetch(baseURL + "get_by_customerid/" + input, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const result = await response.json();
            // console.log(result);
            set((state) => ({data: (state.customer = result), loadingCustomer: false}));
        }
        catch(error){
            set(()=>({customerHasError:true, loadingCustomer:false}));
        }
    }
}));

export {customersStore};
