import PackageContent from './PackageContent';

import {useState} from "react";
import SalaryExample from '../SalaryExample/SalaryExample';
import {choiceStore} from "../LanguageChoice/choice-store";


const Package = () => {
    const [packageTextId, setPackageTextId] = useState(0);

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================
    const handlePackageContent = (event) => {
        setPackageTextId(event.target.id);
        console.log('handlePackageContent:' + event.target.id);
    }

    const packageContent = [
        {
            icon: "fa-thin fa-euro-sign",
            english:
                {
                    header1: "Your Income",
                    text: "Your income is 70% of your revenue. You choose the job, the rate, the distance and the number of hours.",
                    extra_text: "This is an example"
                },
            dutch:
                {
                    header1: "Jouw Inkomen",
                    text: "Jouw inkomen is 70% van je omzet. Jij kiest de klus, het tarief, de afstand en het aantal uur.",
                    extra_text: "Dit is een voorbeeld"

                }
        },
        {
            icon: "fa-thin fa-location-dot",
            english:
                {
                    header1: "Travel",
                    text: "We cover €850,- travel costs. You can choose any car or bicycle you want. Maybe you want to travel by train. The choice is yours even if you want to combine."
                },
            dutch:
                {
                    header1: "Reizen",
                    text: "Wij dekken €850,- reis kosten. Kies een auto, een fiets of misschien wil je met de trein. Het is jouw keuze ook als je wilt combineren.",

                }
        },
        {
            icon: "fa-thin fa-heart",
            english:
                {
                    header1: "Care",
                    text: "We make sure you are coved. At n-TGR we have incapacitated insurance, maternity-leave, sick-leave and coverage for long-term illness as options."
                },
            dutch:
                {
                    header1: "Zorg",
                    text:"Wij zorgen voor onze mensen. n-TGR heeft een arbeidsongeschiktheid verzekering, zwangerschapsverlof en ziekte kosten dekking bij een lang ziekbed. "
                }
        },
        {
            icon: "fa-thin fa-house-laptop",
            english:
                {
                    header1: "Work",
                    text: "We become partners. n-TGR makes sure you are working. Most customers expect you to be on site for 1 or 2 days. We take care of your home workplace."
                },
            dutch:
                {
                    header1: "Werk",
                    text:"We worden partners. n-TGR zorgt ervoor dat je een klus hebt. De meeste klanten verwachten je 1 a 2 dagen on site. Wij zorgen voor een optimale thuiswerk plek. "
                }
        }
    ]

    const [salaryExampleVisable, setSalaryExampleVisable] = useState(false);


    const showSalaryExample = () => {
        setSalaryExampleVisable(true);
    }
    const hideSalaryExample = () => {
        setSalaryExampleVisable(false);
    }

    return (
        <div className="package">
            <div className="package-header">
                {packageTextId == 0 &&
                    <div className="package-header-cell package-one-box-shadow-right" id="0"
                         onClick={handlePackageContent}>
                        <i className="fa-light fa-euro-sign package-header-icon" id="0">{" "}</i>
                    </div>
                }
                {packageTextId != 0 &&
                    <div className="package-header-cell package-header-cell-grey" id="0"
                         onClick={handlePackageContent}>
                        <i className="fa-light fa-euro-sign package-header-icon" id="0">{" "}</i>
                    </div>
                }
                {packageTextId == 1 &&
                    <div className="package-header-cell package-two-box-shadow" id="1" onClick={handlePackageContent}>
                        <i className=" fa-light fa-location-dot package-header-icon" id="1">{" "}</i>
                    </div>
                }
                {packageTextId != 1 &&
                    <div className="package-header-cell package-header-cell-grey" id="1" onClick={handlePackageContent}>
                        <i className=" fa-light fa-location-dot package-header-icon" id="1">{" "}</i>
                    </div>
                }

                {packageTextId == 2 &&
                    <div className="package-header-cell package-two-box-shadow" id="2" onClick={handlePackageContent}>
                        <i className="fa-light fa-heart package-header-icon" id="2">{" "}</i>
                    </div>
                }
                {packageTextId != 2 &&
                    <div className="package-header-cell package-header-cell-grey" id="2" onClick={handlePackageContent}>
                        <i className="fa-light fa-heart package-header-icon" id="2">{" "}</i>
                    </div>
                }

                {packageTextId == 3 &&
                    <div className="package-header-cell package-one-box-shadow-left" id="3"
                         onClick={handlePackageContent}>
                        <i className="fa-light fa-house-laptop package-header-icon" id="3">{" "}</i>
                    </div>
                }
                {packageTextId != 3 &&
                    <div className="package-header-cell package-header-cell-grey" id="3" onClick={handlePackageContent}>
                        <i className="fa-light fa-house-laptop package-header-icon" id="3">{" "}</i>
                    </div>
                }
            </div>
            <div className="package-content">
                {!nederlands && <PackageContent
                    header1={packageContent[packageTextId].english.header1}
                    header2={packageContent[packageTextId].english.header2}
                    text={packageContent[packageTextId].english.text}
                    extra_text={packageContent[packageTextId].english.extra_text}
                    icon={packageContent[packageTextId].icon}
                    showTheExtra={showSalaryExample}
                />}
                {nederlands && <PackageContent
                    header1={packageContent[packageTextId].dutch.header1}
                    header2={packageContent[packageTextId].dutch.header2}
                    text={packageContent[packageTextId].dutch.text}
                    extra_text={packageContent[packageTextId].dutch.extra_text}
                    icon={packageContent[packageTextId].icon}
                    showTheExtra={showSalaryExample}
                />}

            </div>
            {salaryExampleVisable && <SalaryExample onHide={hideSalaryExample}/>}
        </div>
    )
}
export default Package;