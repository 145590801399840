import {Link} from "react-router-dom";
const AboutUsContent = (props) => {
    const iconClass = props.icon + "";
    return (
        <div className="about-us-content">
            <div className="about-us-content-cell about-us-content-icon">
                <i className={iconClass}>{" "}</i>
            </div>
            <div className="about-us-content-cell about-us-content-header">{props.header}</div>
            <Link to="/contact-page" className="about-us-content-cell about-us-content-contact">
                <i className="fa-thin fa-envelope">{" "}</i>
            </Link>
            <div className="about-us-content-cell about-us-content-text">{props.text}</div>
        </div>
    )
}

export default AboutUsContent;
