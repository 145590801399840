import nederlandse_vlag from '../../assets/images/nederlandse_vlag.jpg';
import engelse_vlag from '../../assets/images/engelse_vlag.jpg';
import {useEffect} from "react";
import {choiceStore} from "./choice-store";

const LanguageChoice = () => {

    //============choiceStore
    const setDutch = choiceStore((state)=>state.setNederlands);
    const dutch = choiceStore((state)=>state.nederlands);
    //============choiceStore
    // const [dutch, setDutch] = useState(false);

    const handleChoice = () => {
        setDutch(!dutch)
    }

    return (
        <div className="language">
            {dutch && <img className="language-flag" src={engelse_vlag} alt="" onClick={handleChoice}/>}
            {!dutch && <img className="language-flag" src={nederlandse_vlag} alt="" onClick={handleChoice}/>}
        </div>
    )
}

export default LanguageChoice;