import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ntgrVacancyPageStore} from "./ntgr-vacancy-page-store";
import VacancyResponse from '../VacancyResponse/VacancyResponse';
import {choiceStore} from "../LanguageChoice/choice-store";


const NTGRVacancyPage = () => {
    //Can not be a page on top, it has to be a page by itself because of the content.
    const vacancyPageVisible = ntgrVacancyPageStore((state) => state.vacancyPageVisible);
    const setVacancyPageVisible = ntgrVacancyPageStore((state) => state.setVacancyPageVisible);


    let vacancies = [];
    let currentVacancy = null;
    const [vacancyResponseVisible, setVacancyResponseVisible] = useState(false);

    //===========choiceStore ================
    const nederlands = choiceStore((state) => state.nederlands)
    //===========choiceStore ================


    let educationText = "Education";
    let startDateText = "Start Date";
    let descriptionText = "Description";
    let requirementsText = "Requirements";
    let applyText = "Apply";

    if (nederlands) {

        educationText = "Opleiding";
        startDateText = "Begin Datum";
        descriptionText = "Beschrijving";
        requirementsText = "Eisen"
        applyText = "Solliciteer"
    }

    const navigate = useNavigate();

    const showVacancyResponse = () => {
        setVacancyResponseVisible(true);
    }
    const hideVacancyResponse = () => {
        setVacancyResponseVisible(false);
    }


    useEffect(() => {
        let Vacancies = sessionStorage.getItem('Vacancies');
        if (!Vacancies) {
            navigate('/');
        }

    }, [navigate]);

    vacancies = JSON.parse(sessionStorage.getItem('Vacancies'));//         1. get the cache
    let vacancyId = sessionStorage.getItem('currentVacancyId');//              2. get the vacancyId
    if (vacancies) {//only if there are vacancies you should do this. Remember useEffect will fire but the rest will also be done  so that needs protecting.
        currentVacancy = vacancies.find(val => val.vacancyId == vacancyId);//3. get the currentVacancy
    }
    //
    if (!vacancyPageVisible) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        setVacancyPageVisible(true);
    }

    return (
        <div id="vacancy-page" className="ntgr-vacancy-page">
            <div className="ntgr-vacancy-page-container">
                {currentVacancy &&
                    <div className="ntgr-vacancy-page-container-content">
                   <span className="ntgr-vacancy-page-heading">
                      {currentVacancy.name}
                   </span>
                        <div className="ntgr-vacancy-page-label ntgr-vacancy-page-label-education">{educationText}</div>
                        <div
                            className="ntgr-vacancy-page-label ntgr-vacancy-page-label-start-date">{startDateText}</div>
                        <div
                            className="ntgr-vacancy-page-text ntgr-vacancy-page-text-education">{currentVacancy.education}</div>
                        <div
                            className="ntgr-vacancy-page-text ntgr-vacancy-page-text-start-date">{currentVacancy.start_date}</div>
                        <div
                            className="ntgr-vacancy-page-label ntgr-vacancy-page-label-description">{descriptionText}</div>
                        <div className="ntgr-vacancy-page-text ntgr-vacancy-page-text-description"
                             dangerouslySetInnerHTML={{__html: currentVacancy.description}}/>
                        <div className="ntgr-vacancy-page-divider ntgr-vacancy-page-divider-2">{""}</div>
                        <div
                            className="ntgr-vacancy-page-label ntgr-vacancy-page-label-requirements">{requirementsText}</div>
                        <div className="ntgr-vacancy-page-text ntgr-vacancy-page-text-requirements"
                             dangerouslySetInnerHTML={{__html: currentVacancy.requirements}}/>
                        <div className="ntgr-vacancy-page-respond-container">
                            <div className="btn ntgr-vacancy-page-respond"
                                 onClick={showVacancyResponse}>{applyText}</div>
                        </div>
                    </div>}

            </div>
            {/*This part is the VacancyResponse that is shown when the response button is clicked.*/}
            {vacancyResponseVisible &&
                <VacancyResponse hideVacancyResponse={hideVacancyResponse} currentVacancy={currentVacancy}/>}
        </div>
    )
        ;
}
export default NTGRVacancyPage;